<template>
<div>
  <div class="access">
    <img class="parking" src="../assets/grv2.webp">
    <h2 class="name_h2">レグノ GRVII</h2>

    <p class="access_p">
    レグノ GRVIIは、静粛性乗り心地に優れたミニバン用タイヤです。<br>
    最上級の柔らかい乗り心地のレグノは、送迎車にもおすすめです。<br>
    GRVIIはより上質で快適な車内空間を演出してくれます。
    </p>

    <div class="square-01">
    </div>

    <h3 class="recom_h3">推奨車種</h3>
    <p class="recom">ミニバン / 一部SUV</p>

    <div class="charge2">
    <div class="charge_table">
      <h2 class="charge_table_h2"><span class="charge_table_span">サイズ表・メーカー希望小売価格</span><br>※4本購入で値引きできます</h2>
      <table class="biz-hour">
        <tbody>
          <tr>
            <th>サイズ</th>
            <th>税込<span class="price_span"> 1本</span></th>
          </tr>
          <tr>
            <td>20インチ</td>
          </tr>
          <tr>
            <td>255/35R20 93W</td>
            <td class="reds"><span class="red_p">¥</span>99,220</td>
          </tr>
          <tr>
            <td>245/35R20 95W XL</td>
            <td class="reds"><span class="red_p">¥</span>96,470</td>
          </tr>
          <tr>
            <td>245/40R20 95W</td>
            <td class="reds"><span class="red_p">¥</span>86,020</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>19インチ</td>
          </tr>
          <tr>
            <td>245/40R19 98W	XL</td>
            <td class="reds"><span class="red_p">¥</span>74,800</td>
          </tr>
          <tr>
            <td>245/45R19 98W</td>
            <td class="reds"><span class="red_p">¥</span>67,980</td>
          </tr>
          <tr>
            <td>225/45R19 96W	XL</td>
            <td class="reds"><span class="red_p">¥</span>61,490</td>
          </tr>
          <tr>
            <td>255/50R19 103W</td>
            <td class="reds"><span class="red_p">¥</span>57,970</td>
          </tr>
          <tr>
            <td>225/55R19 99V</td>
            <td class="reds"><span class="red_p">¥</span>49,830</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>18インチ</td>
          </tr>
          <tr>
            <td>245/45R18 100W XL</td>
            <td class="reds"><span class="red_p">¥</span>63,030</td>
          </tr>
          <tr>
            <td>225/45R18 95W	XL</td>
            <td class="reds"><span class="red_p">¥</span>56,540</td>
          </tr>
          <tr>
            <td>235/50R18 101V XL</td>
            <td class="reds"><span class="red_p">¥</span>49,940</td>
          </tr>
          <tr>
            <td>225/50R18 95V</td>
            <td class="reds"><span class="red_p">¥</span>48,400</td>
          </tr>
          <tr>
            <td>215/50R18 92V</td>
            <td class="reds"><span class="red_p">¥</span>46,200</td>
          </tr>
          <tr>
            <td>225/55R18 98V</td>
            <td class="reds"><span class="red_p">¥</span>47,520</td>
          </tr>
          <tr>
            <td>215/55R18 95V</td>
            <td class="reds"><span class="red_p">¥</span>45,980</td>
          </tr>
          <tr>
            <td>235/60R18 103V</td>
            <td class="reds"><span class="red_p">¥</span>42,350</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>17インチ</td>
          </tr>
          <tr>
            <td>215/45R17 91W	XL</td>
            <td class="reds"><span class="red_p">¥</span>45,540</td>
          </tr>
          <tr>
            <td>225/50R17 98V	XL</td>
            <td class="reds"><span class="red_p">¥</span>45,430</td>
          </tr>
          <tr>
            <td>215/50R17 95V XL</td>
            <td class="reds"><span class="red_p">¥</span>43,010</td>
          </tr>
          <tr>
            <td>225/55R17 97W</td>
            <td class="reds"><span class="red_p">¥</span>43,780</td>
          </tr>
          <tr>
            <td>215/55R17 94V</td>
            <td class="reds"><span class="red_p">¥</span>41,250</td>
          </tr>
          <tr>
            <td>205/55R17 91V</td>
            <td class="reds"><span class="red_p">¥</span>39,710</td>
          </tr>
          <tr>
            <td>225/60R17 99H</td>
            <td class="reds"><span class="red_p">¥</span>37,950</td>
          </tr>
          <tr>
            <td>215/60R17 96H</td>
            <td class="reds"><span class="red_p">¥</span>35,970</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>16インチ</td>
          </tr>
          <tr>
            <td>205/55R16 91V</td>
            <td class="reds"><span class="red_p">¥</span>33,990</td>
          </tr>
          <tr>
            <td>215/60R16 95H</td>
            <td class="reds"><span class="red_p">¥</span>33,330</td>
          </tr>
          <tr>
            <td>205/60R16 92H</td>
            <td class="reds"><span class="red_p">¥</span>31,680</td>
          </tr>
          <tr>
            <td>195/60R16 89H</td>
            <td class="reds"><span class="red_p">¥</span>30,030</td>
          </tr>
          <tr>
            <td>215/65R16 98H</td>
            <td class="reds"><span class="red_p">¥</span>35,860</td>
          </tr>
          <tr>
            <td>205/65R16 95H</td>
            <td class="reds"><span class="red_p">¥</span>34,100</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>15インチ</td>
          </tr>
          <tr>
            <td>215/65R15 96H</td>
            <td class="reds"><span class="red_p">¥</span>30,580</td>
          </tr>
          <tr>
            <td>205/65R15 94H</td>
            <td class="reds"><span class="red_p">¥</span>28,160</td>
          </tr>
          <tr>
            <td>195/65R15 91H</td>
            <td class="reds"><span class="red_p">¥</span>25,740</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <CarouselsMinivan/>

    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p class="address_p">静岡県御殿場市茱萸沢241-1</p>
    <a href="https://goo.gl/maps/oabDqVGbjJQn65yk8">Google Maps</a>
  </div>
  <div>
    <Footer/>
  </div>
</div>
</template>

<script>
import CarouselsMinivan from '@/components/CarouselsMinivan.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    CarouselsMinivan,
    Footer
  }
}
</script>

<style scoped>
.recom_h3{
  text-align: left;
}
.recom{
  padding-bottom: 3rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list{
  padding-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list2{
  padding-bottom: 6rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.access{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
}
.access p{
  text-align:left;
}
.access_p{
  padding-bottom: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.name_h2{
  padding-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 2rem;
}

.about_p{
  margin-top: -4rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 4rem;
}
.address_p{
  font-weight: 600;
  font-size: 0.8rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (max-width: 640px)
.page .moretodiscover .square-01 {
    width: 67.5vw;
    height: calc(100% - 25vw);
    position: absolute;
    top: 25vw;
    right: 0;
    background-color: #dae4e8;
}
.charge2{
  padding-bottom: 3rem;
  padding-top: 0rem;
}
@media screen and (min-width: 520px) {
  .charge_table table{
    margin: 0 auto;
    width: 70%;
  }
  .charge_table ul{
    text-align: center;
    display: inline-block;
  }
  ul{
    display: inline-block;
    text-align: center;
  }
  .price_class1{
    display: inline-block;
    text-align: center;
  }
  .price_class2{
    text-align: center;
    display: inline-block;
  }
  .price_class3{
    text-align: center;
    display: inline-block;
  }
}
@media screen and (min-width: 1280px) {
  .charge_table table{
    margin: 0 auto;
    width: 70%;
  }
  .charge_table ul{
    text-align: center;
    display: inline-block;
  }
  ul{
    display: inline-block;
    text-align: center;
  }
  .price_class1{
    display: inline-block;
    text-align: center;
  }
  .price_class2{
    text-align: center;
    display: inline-block;
    padding-right: 10%;
  }
  .price_class3{
    text-align: center;
    display: inline-block;
  }
}
.charge_table_h2{
  text-align: left;
}
.charge_table_span{
  font-size: 1rem;
}
.price_span{
  font-size: 0.8rem;
}
.charge_table{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.biz-hour {
  border-collapse: collapse;
}
@media screen and (max-width: 414px) {
  .biz-hour {/* スマホのときだけ親要素いっぱいに */
    width: 100%;
  }
}

.biz-hour th, .biz-hour td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  /* ２番目以降の線 */
  /* 色はお好みで */
  border-bottom: 1px solid black;
  font-weight: bold;
}

/* 上段の背景色 */
.biz-hour th {
  /* background: black; */
  font-weight: bold;
}

/* 土曜日の文字色 */
.biz-hour th.sat {
  color: #3db2da;
}

/* 日曜日の文字色 */
.biz-hour th.sun {
  color: #e66a6a;
}
</style>
